<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路编号">
				<el-input class="el_input" v-model="form.bl_num" placeholder="线路编号" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="货主公司">
				<el-input class="el_input" v-model="form.shipper_company_name" placeholder="货主公司名称搜索" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="车主手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="大区搜索">
				<el-input class="el_input" v-model="form.area_id" placeholder="大区名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="销售电话">
				<el-input class="el_input" v-model="form.saler_tel" placeholder="销售电话搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未发车" value="1"></el-option>
					<el-option label="已发车" value="2"></el-option>
					<el-option label="已到货" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.i_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未开票" value="1"></el-option>
					<el-option label="开票中" value="2"></el-option>
					<el-option label="已开票" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settled_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未结算" value="1"></el-option>
					<el-option label="部分结算" value="2"></el-option>
					<el-option label="结算完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报状态">
				<el-select class="el_input" v-model="form.upl_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上报" value="1"></el-option>
					<el-option label="运单上报成功" value="2"></el-option>
					<el-option label="运单上报失败" value="3"></el-option>
					<el-option label="流水上报成功" value="4"></el-option>
					<el-option label="流水上报失败" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单删除">
				<el-select class="el_input" v-model="form.is_show" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="待审核" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运费设置">
				<el-select class="el_input" v-model="form.is_set_freight" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item class="el_form_item" label="线路搜索" >
				<el-autocomplete
				class="inline-input"
				v-model="form.bl_name"
				:fetch-suggestions="querySearch"
				placeholder="线路名称"
				@focus="bl_focus"
				></el-autocomplete>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="发到时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="发车日期"
					end-placeholder="到货日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<!-- <el-form-item class="el_form_item elfrom_item" label="开票时间">
				<div class="block">
				<el-date-picker
					v-model="invoice_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item> -->
			<el-form-item class="el_form_item elfrom_item" label="建单时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr1"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_form_open">更多条件</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总计:{{total}}元</span>
			<span>总运费:{{list.total_freight}}元</span>
			<span v-if="list.total_service>0">服务费{{list.total_service}}元</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="tords_list_choose" >
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="运单/组别/上游单号" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">线路{{scope.row.bl_num}}</div>
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:"无"}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:"无"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}/{{scope.row.case_other_obj.addr_info?scope.row.case_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}/{{scope.row.aim_other_obj.addr_info?scope.row.aim_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">发车日期:{{scope.row.case_other_obj.start_time}}{{(scope.row.case_other_obj.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_type}}/{{scope.row.cargo_name}}</div>
						<div class="tab_line_item">发：{{parseFloat(scope.row.cargo_weight_start)==0?scope.row.cargo_weight +'吨':scope.row.cargo_weight_start+"吨"}}</div>
						<div class="tab_line_item">到：{{parseFloat(scope.row.cargo_weight_arrived)==0?scope.row.cargo_weight+'吨' :scope.row.cargo_weight_arrived+"吨"}}</div>
						<div class="tab_line_item">重量：{{scope.row.cargo_weight+'吨'}}</div>
						<div class="tab_line_item">体积：{{scope.row.cargo_volume==0?"无体积":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">{{scope.row.settlement_type_text}}/{{scope.row.is_need_return_text}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/服务费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">应收:{{scope.row.cost_info.freight_total_text}}</div>
						<div class="tab_line_item">应付:{{scope.row.cost_info.give_carrier_total_text}}</div>
						<div class="tab_line_item">服务费:{{scope.row.cost_info.service_charge}}</div>
						<div class="tab_line_item">已支付服务费:{{scope.row.service_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}}/{{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_company_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车主/司机/货车信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">车主:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.truck_plate_num}}{{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作网点" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名:{{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">电话:{{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">网点:{{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
						<div class="tab_line_item">
							{{scope.row.lock_status_text}} 
						</div>
						<div class="tab_line_item">{{scope.row.upl_text}}</div>
						<div class="tab_line_item" style="color:red;font-size:16px;font-weight: bolder;" v-if="scope.row.is_show ==2">已删除</div>
					</template>
				</el-table-column>
				<el-table-column label="上报状态" width="80px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tax_upl_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="时效信息" width="155px">
					<template slot-scope="scope">
						<div class="tab_line_item">接:{{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">发:{{scope.row.start_time_text}}</div>
						<div class="tab_line_item">到:{{scope.row.arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="132">
					<template slot-scope="scope">
						<!-- <el-button @click="truck_trajectory_open(scope.row)" size="mini" type="text">轨迹</el-button> -->
						<el-button @click="voucher_show(scope.row)" size="mini" type="text" >凭证</el-button>
						<el-button  class="btn_left" @click="show_agreement(scope.row)" size="mini" type="text" >查看协议</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-backtop
		target=".page"
		:bottom="100"
		:visibility-height="50"
		></el-backtop>
		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>
		<!-- 搜索表单 -->
		<serForm 
			:is_show="ser_form_is_show" 
			:form="form" 
			@sub="ser_form_sub"
		></serForm>

	<!-- 凭证界面 -->
		<voucher :truck_tord_num="voucher.truck_tord_num" :is_show="voucher.is_show"></voucher>

		<!-- 协议查看 -->
		<xieYi 
			:is_show="agreement.is_show" 
			:data='agreement.data'
		></xieYi>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import serForm from './ser_form.vue'
	import trucktTrajectory from '@/components/map/amap/trajectory.vue'
	import voucher from '../../voucher/voucher_before_end.vue'
	import xieYi from './xieyi.vue'
	export default {
		components:{
			serForm,
			trucktTrajectory,
			voucher,
			xieYi
		},
		data() {
			return {
				date_value:'',
				invoice_time:'',
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				total:'',//总计费用
				//搜索界面是否打开
				ser_form_is_show:0,

				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					shipper_tel:'',//货主手机号
					shipper_company_name:'新疆万博煤业有限公司',//货主公司名称
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					status:'',//状态
					payed_status:'',//支付状态
					settled_status:'',//结算状态
					upl_status:'',//上报状态
					is_set_freight:'',//运费设置
					bl_name:'',//线路名称搜索
					i_status:'',//开票状态
					area_id:'',//大区搜索
					saler_tel:'',//销售电话搜索
					start_time_start:0,
					arrived_time_end:0,

					invoiced_time_start:'', //开票开始时间
                    invoiced_time_end:'',//开票结束时间

					is_show:'',//运单申请删除

					creat_time_arr:[]//建单时间

				},
				creat_time_arr1:'',
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight:'',//总金额
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:'',
				},

				//货车轨迹
				trajectory:{
					is_show:false,
					case_county:null,
					aim_county:null,
					start_time:null,
					end_time:null,
					truck_plate_num:null,
				},
				//协议弹窗
				agreement:{
					is_show:0,
					data:'',
				},
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {
			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.weight={
					cargo_weight_start:item.cargo_weight_start,
					cargo_weight_arrived:item.cargo_weight_arrived,
					cargo_weight:item.cargo_weight
				}
				this.voucher.truck_tord_num=item.truck_tord_num
			},
			//协议查看
			show_agreement(item){
				this.agreement.is_show++
				this.agreement.data =item
			},
			//运单审核删除
			act_delete(item,num){
				var tisp = ''
				if(num ==1){
					tisp = '取消删除'
				}else{
					tisp = '确定删除'
				}
				//提示
				this.$my.other.confirm({
					content:"您的操作是" + tisp,
					confirm:()=>{
							
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_hide_admin',
								truck_tord_nums:[item.truck_tord_num],
								action_status:num

							},
							callback:(data)=>{
							
								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							},
						});
					},
				});
			},
			//勾选
			tords_list_choose(event){
				//置入
				this.tords_choosed=event
			},
			
			tords_freight_check(){
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}
				
				//汇集未支付
				let tord_arr=[];
				for(var item of this.tords_choosed){
					if(item.payed_status==1&&item.settlement_type==1){
						tord_arr.push(item.truck_tord_num);
					}
				}

				if(tord_arr.length==0){
					this.$my.other.msg({
						str:'缺少符合条件的运单(已支付，切按吨结算)',
						type:'failed'
					});
				}
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量重新核对并修改运费',请确认",
					confirm:()=>{
							
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_cost_admin',
								truck_tord_nums:tord_arr,
							},
							callback:(data)=>{
							
								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			bl_focus(){
				//格式判断
				if(!this.$my.check.is_tel(this.form.shipper_tel)){
					this.$my.other.msg({
						type:"warning",
						str:'货主电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.form.shipper_tel
					},
					callback:(data)=>{
						data=data.list[0];
						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}
						if(!data.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'缺少货主信息'
							});
							return;
						}
						//去服务器读取线路
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_real_time_bl_list_admin',
								shipper_num:data.user_num,
							},
							callback:(data)=>{
								var bl_list = []
								//遍历
								for(var item of data.list){

									//结算方式
									var settlement_type_text=""
									switch(item.is_need_return){
										case '1':settlement_type_text="按吨数结算";break;
										case '2':settlement_type_text="按方数结算";break;
										case '3':settlement_type_text="按趟结算";break;
									}

									//是否是双边
									var is_need_return_text=""
									switch(item.is_need_return){
										case '1':is_need_return_text="双边";break;
										case '2':is_need_return_text="单边";break;
									}

									//装货地其他数据
									item.case_other_obj=JSON.parse(item.case_other);

									//卸货地其他数据
									item.aim_other_obj=JSON.parse(item.aim_other);

									//汇集路线数据
									item.info_text=item.case_prov+'/'+item.case_city+'/'+item.case_county+' ---- '+item.aim_prov+'/'+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')'
									bl_list.push({
										value:item.bl_name,
										address:item.bl_name
									})
								}
								this.restaurants = bl_list
							}
						})
					}
				});
			},
		   querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			//轨迹
			truck_trajectory_open(item){
				if(item.status==1){
					this.$my.other.msg({
						type:'warning',
						str:'此运单未发车,不能查看轨迹'
					})
					return;
				}
				this.trajectory.case_county=item.case_county;
				this.trajectory.aim_county=item.aim_county;
				this.trajectory.start_time=parseInt(item.start_time);
				this.trajectory.end_time=item.status==2?parseInt(new Date().getTime()/1000):parseInt(item.arrived_time);
				this.trajectory.truck_plate_num=item.truck_plate_num;
				this.trajectory.is_show=true;
			},
			truck_trajectory_close(){
				this.trajectory.is_show=false;
				this.trajectory.case_county=null;
				this.trajectory.aim_county=null;
				this.trajectory.start_time=null;
				this.trajectory.end_time=null;
				this.trajectory.truck_plate_num=null;
			},

			//打开运单参数界面
			tord_set_open(item){

				//置入运单编号
				this.tord_set.truck_tord_num=item.truck_tord_num

				//打开
				this.tord_set.is_show++
			},

			//上报记录
			tord_upl_open(item){
				this.upl_list.tord_info={
					truck_tord_num:item.truck_tord_num,
					truck_plate_num:item.truck_plate_num,
					upl_status:item.upl_status,
					start_time:item.start_time,
					arrived_time:item.arrived_time,
					case_code:item.case_other_obj.county_code,
					aim_code:item.aim_other_obj.county_code,
				}
				this.upl_list.is_show++
			},

			//运单锁定相关
			tord_lock_open(item){//打开运单锁定界面
				this.lock.truck_tord_num=item.truck_tord_num
				this.lock.is_show++
			},
			tord_lock_close(obj){//关闭运单锁定界面
				
				//锁定原因
				if(!obj.lock_reason.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少锁定原因'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_lock_by_admin',
						lock_reason:obj.lock_reason.trim(),
						truck_tord_num:this.lock.truck_tord_num,
					},
					callback:(data)=>{

						this.$my.other.msg({
							type:"success",
							str:'锁定成功'
						});

						//关闭弹出层
						this.lock.is_show=0
	
						//刷新本页
						this.get_page_data()
					},
				});
			},
			tord_unlock(item){//运单解锁

				//提示
				this.$my.other.confirm({
					content:"您的操作是'运单解锁',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_unlock_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								this.$my.other.msg({
									type:"success",
									str:'锁定成功'
								});

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},


			
			//更多条件
			ser_form_open(){//打开

				this.ser_form_is_show++
			},
			ser_form_sub(obj){//提交

				//置入表单数据
				for(var key in obj){

					if(!obj[key]){
						continue;
					}

					this.form[key]=obj[key];

					//特殊处理
					switch(key){

						case 'cargo_creat_time'://货源创建时间
							this.form.cargo_creat_time_arr=[
								(obj.cargo_creat_time[0].getTime() / 1000).toFixed(0),
								(obj.cargo_creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'creat_time'://成交时间(运单创建时间)
							this.form.creat_time_arr=[
								(obj.creat_time[0].getTime() / 1000).toFixed(0),
								(obj.creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'start_time'://发车时间
							this.form.start_time_arr=[
								(obj.start_time[0].getTime() / 1000).toFixed(0),
								(obj.start_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'arrived_time'://到货时间
							this.form.arrived_time_arr=[
								(obj.arrived_time[0].getTime() / 1000).toFixed(0),
								(obj.arrived_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'payed_time'://支付完成时间
							this.form.payed_time_arr=[
								(obj.payed_time[0].getTime() / 1000).toFixed(0),
								(obj.payed_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'invoiced_time'://开票完成时间
							this.form.invoiced_time_arr=[
								(obj.invoiced_time[0].getTime() / 1000).toFixed(0),
								(obj.invoiced_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'settled_time'://结算完成时间
							this.form.settled_time_arr=[
								(obj.settled_time[0].getTime() / 1000).toFixed(0),
								(obj.settled_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'tord_upld_time'://运单上报时间
							this.form.tord_upld_time_arr=[
								(obj.tord_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.tord_upld_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'outcash_upld_time'://出金流水上报时间
							this.form.outcash_upld_time_arr=[
								(obj.outcash_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.outcash_upld_time[1].getTime() / 1000).toFixed(0),
							];break;
					}
				}

				//关闭页面
				this.ser_form_is_show=0

				//读取数据
				this.page_ser()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1
				if (this.date_value) {
					this.form.start_time_start = parseInt(this.date_value[0] / 1000);
					this.form.arrived_time_end= parseInt(this.date_value[1] / 1000);
				} else {
					this.form.start_time_start = "";
					this.form.arrived_time_end = "";
				}
				if (this.invoice_time) {
					this.form.invoiced_time_start = parseInt(this.invoice_time[0] / 1000);
					this.form.invoiced_time_end= parseInt(this.invoice_time[1] / 1000);
				} else {
					this.form.invoice_time_start = "";
					this.form.invoice_time_end = "";
				}
				if(this.creat_time_arr1){
					this.form.creat_time_arr[0] = parseInt(this.creat_time_arr1[0] / 1000);
				    this.form.creat_time_arr[1] = parseInt(this.creat_time_arr1[1] / 1000);
				}else{
					this.form.creat_time_arr = []
				}
				
				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					
					//运单编号相关
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号
					is_set_freight:'',
					//货主相关
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					area_id:'',//大区搜索
					saler_tel:'',//销售电话搜索
					//车主/司机/车辆
					truck_owner_tel:'',//车主手机号
					truck_owner_name:'',//车主姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					bl_name:'', //线路名称

					//状态相关
					status:'',//状态(1:待发车,2:运输中,3:已到货)
					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)
					payed_status:'',//支付状态(1:未支付,2:部分支付,3:支付完毕)
					invoiced_status:'',//开票状态(1:未开票,2:部分开票,3:开票完毕)
					settled_status:'',//结算状态(1:未结算,2:部分结算,3:结算完毕)
					upl_status:'',//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
					upl_aim:'',//运单上报目标,上报后有值(类似:zhejiang;anhui....)

					//货源数据相关
					cargo_name:'',//货物名称
					cargo_type:'',//货物类型
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式(1:车主抢单,2:货主指定后车主接单,3:车主竞价,4:车主扫码接单,5:网点线下匹配后报单,6:合作方协助匹配后报单,7:后台匹配,8:后台添加)
					outlets_num:'',//网点编号

					//货车类型相关
					need_truck_type:'',//租用货车类型
					need_truck_type2:'',//租用货车细分类型
					need_truck_types:null,//货车类型

					//发到货地相关
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					start_time_start:'',
					arrived_time_end:'',

					invoiced_time_start:'', //开票开始时间
                    invoiced_time_end:'',//开票结束时间

					//时效相关
					cargo_creat_time:null,//货源创建时间
					creat_time:null,//成交时间(运单创建时间)
					start_time:null,//发车时间
					arrived_time:null,//到货时间
					payed_time:null,//支付完成时间
					invoiced_time:null,//开票完成时间
					settled_time:null,//结算完成时间
					tord_upld_time:null,//运单上报时间
					outcash_upld_time:null,//出金流水上报时间

					is_show:'',

					creat_time_arr:[]
				}
				this.date_value=''
				this.invoice_time = ''
				//读取
				this.get_page_data()
				
			},

			//导出搜索结果
			tord_export_open(){//打开界面

				//置入条件
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},
			
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						is_get_truck_info:1,
						is_get_tord_outlets:1,
						...this.form,
						...this.page,
					},
					callback:(data)=>{
						if(data.code ==0){
							//加载完毕
							this.list.loading=false

							//总数
							this.list.total=data.msg.max

							//总金额
							this.list.total_freight = data.msg.total.total_freight

							//总服务费
							this.list.total_service = data.msg.total.total_service

							//总计费用
							this.total = (Number(data.msg.total.total_freight) + Number(data.msg.total.total_service)).toFixed(2)
							//预处理
							for(let item of data.msg.list){
								item.cargo_weight = Number(item.cargo_weight).toFixed(2)
								//成交时间
								item.creat_time_text=this.$my.other.totime(item.creat_time);
								
								//发车时间
								if(item.start_time>0){//已发车
									item.start_time_text=this.$my.other.totime(item.start_time);
								}else item.start_time_text="未发车";
								
								//到货时间
								if(item.arrived_time>0){//已到货
									item.arrived_time_text=this.$my.other.totime(item.arrived_time);
								}else item.arrived_time_text="未到货";

								//装货联系人
								if(item.case_link_man){
									item.case_link_man_text=item.case_link_man;
								}else item.case_link_man_text="无";

								//装货联系电话
								if(item.case_link_tel){
									item.case_link_tel_text=item.case_link_tel;
								}else item.case_link_tel_text="无";

								//卸货联系人
								if(item.aim_link_man){
									item.aim_link_man_text=item.aim_link_man;
								}else item.aim_link_man_text="无";

								//卸货联系电话
								if(item.aim_link_tel){
									item.aim_link_tel_text=item.aim_link_tel;
								}else item.aim_link_tel_text="无";

								/* 装货地其他数据(json) */
								item.case_other_obj=JSON.parse(item.case_other);

								//装货时间
								if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

								}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

								}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

								}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

									item.case_other_obj.case_date="未设置";
								}

								//发车时间
								if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

								}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

								}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

								}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有
									
									item.case_other_obj.start_time="未设置";
								}

								//发车时间是否强制校验
								switch(item.case_other_obj.start_time_is_check){
									case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
									case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
								}
								if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
									item.case_other_obj.start_time_is_check_text=""
								}

								/* 卸货地其他数据(json) */
								item.aim_other_obj=JSON.parse(item.aim_other);

								//卸货时间
								if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

								}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

								}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

								}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有
									
									item.aim_other_obj.aim_date="未设置";
								}

								//运单状态
								switch(item.status){
									case '1':item.status_text="待发车";break;
									case '2':item.status_text="运输中";break;
									case '3':item.status_text="已到货";break;
								}

								//锁定状态
								switch(item.lock_status){
									case '1':item.lock_status_text="已锁定";break;
									case '2':item.lock_status_text="未锁定";break;
								}
								
								//上报状态
								switch(item.tax_upl_status){
									case '1':item.tax_upl_status_text="未同步";break;
									case '2':item.tax_upl_status_text="已同步尚未第一次上传";break;
									case '3':item.tax_upl_status_text="第一次已上传";break;
									case '4':item.tax_upl_status_text="一次失败";break;
									case '5':item.tax_upl_status_text="二次已上传";break;
									case '6':item.tax_upl_status_text="二次失败";break;
									case '7':item.tax_upl_status_text="三次已上传";break;
									case '8':item.tax_upl_status_text="三次失败";break;
									case '9':item.tax_upl_status_text="其他";break;
								}

								//结算方式
								switch(item.settlement_type){
									case '1':item.settlement_type_text="按吨数";break;
									case '2':item.settlement_type_text="按方数";break;
									case '3':item.settlement_type_text="按趟";break;
								}
								
								//是否回程
								switch(item.is_need_return){
									case '1':item.is_need_return_text="需要回程";break;
									case '2':item.is_need_return_text="不需要回程";break;
								}

								//匹配方式
								switch(item.match_way){
									case '1':item.match_way_text="车主抢单";break;
									case '2':item.match_way_text="指定后车主接单";break;
									case '3':item.match_way_text="车主竞价中标";break;
									case '4':item.match_way_text="车主扫码接单";break;
									case '5':item.match_way_text="网点线下匹配";break;
									case '6':item.match_way_text="合作方匹配";break;
									case '7':item.match_way_text="后台匹配";break;
									case '8':item.match_way_text="后台添加";break;
								}

								//应收总额
								if(item.cost_info.freight_total){
									item.cost_info.freight_total_text=item.cost_info.freight_total+" 元";
								}else item.cost_info.freight_total_text="未设置";

								//应付总额
								if(item.cost_info.give_carrier_total){
									item.cost_info.give_carrier_total_text=item.cost_info.give_carrier_total+" 元";
								}else item.cost_info.give_carrier_total_text="未设置";

								//已付总额
								item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+" 元"

								//运费支付状态
								switch(item.payed_status){
									case '1':item.payed_status_text="未支付";break;
									case '2':item.payed_status_text="部分支付";break;
									case '3':item.payed_status_text="支付完毕";break;
								}

								//运费结算状态
								switch(item.settled_status){
									case '1':item.settled_status_text="未结算";break;
									case '2':item.settled_status_text="部分结算";break;
									case '3':item.settled_status_text="结算完毕";break;
								}

								//货车类型
								item.truck_info.truck_type_name_text=`
									${item.truck_info.truck_type_name?'/'+item.truck_info.truck_type_name+'/':''}
									${item.truck_info.truck_type_name2?item.truck_info.truck_type_name2:''}
								`;

								//上报
								switch(item.upl_status){
									case '1':item.upl_text="未上报";break;
									case '2':item.upl_text="运单上报成功";break;
									case '3':item.upl_text="运单上报失败";break;
									case '4':item.upl_text="流水上报成功";break;
									case '5':item.upl_text="流水上报失败";break;
								}
								if(item.upl_aim)item.upl_text+=`(${this.logink_aim_list[item.upl_aim]})`
							}

							//渲染
							this.list.data=data.msg.list
						}else{
							this.list.loading=false
							this.list.data=[]
						}

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:1000px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 10px;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}
	/deep/.el-button--text{
		color: #409eff !important;
	}
	.btn_delete{
		color:red !important
	}
</style>